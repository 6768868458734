.how-it-works-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.how-it-works-container h2 {
  text-align: center;
  color: #001f4d;
  margin-bottom: 20px;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.step {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.step h3 {
  color: #001f4d;
  margin-bottom: 10px;
}

.step p {
  line-height: 1.6;
}

.step strong {
  color: #333;
}
