.footer {
  display: flex;
  flex-direction: column;
  background-color: #34445b; /* Header ile aynı arka plan */
  color: #fff;
  padding: 10px;
  font-family: Arial, sans-serif;
}

.footer-container {
  display: flex; /* Esnek bir düzen oluştur */
  justify-content: space-between; /* Sola ve sağa yaslamak için */
  align-items: top; /* Dikeyde ortalamak için */
  max-width: 1200px;
  margin: 5px 2px 10px 20px;
}

.footer-column {
  display: flex;
  gap: 10px;
  padding: 5px 2px 10px 20px;
}

.footer-logo {
  text-align: left; /* Logo sol tarafa yaslanır */
  padding: 5px 2px 10px 20px;
}

.footer-logo-button {
  background-color: #fbc531;
  color: #333;
  font-size: 2rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 3px;
  width: 190px;
  height: 45px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.footer-logo-button:hover {
  background-color: #f1a902;
}

.footer-slogan {
  font-size: 14px;
  font-style: italic;
  color: #ccc;
  margin-top: 10px;
}

.footer-list {
  list-style: none;
  padding: 0px; 
}

.footer-list li {
  margin-bottom: 10px;
}

.footer-list li a {
  color: #fff;
  text-decoration: none;
}

.footer-list li a:hover {
  text-decoration: underline;
}

.footer-social {
  display: flex;
  gap: 10px;
  padding: 5px 2px 10px 20px;
}

.social-icon {
  font-size: 24px;
  transition: color 0.3s ease;
  color: #fff;
}

.social-icon:hover {
  color: #fbc531;
}

.footer-payments {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.payment-logo {
  width: 350px;
  height: auto;
}

.footer-text {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  color: #aaa;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Küçük ekranlarda sütun yapısına geç */
    align-items: center; /* Ortala */
    gap: 20px;
  }

  .footer-social {
    justify-content: center; /* Mobilde ortala */
  }
}

@media (max-width: 480px) {
  .footer-container {
    flex-direction: column; /* Daha küçük cihazlarda sütun yapısı */
  }

  .footer-social {
    justify-content: center; /* Mobilde ikonları ortala */
  }
}

.footer-contact {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Her satır arasında boşluk bırakır */
}

.footer-contact li {
  display: flex;
  align-items: center; /* İkon ile metni hizalar */
  gap: 10px; /* İkon ile metin arasında boşluk */
  color: #fff;
  font-size: 14px;
}

.contact-icon {
  font-size: 18px;
  color: #fbc531; /* İkon rengi */
}

.contact-icon:hover {
  color: #f1a902; /* Hover renginde değişim */
}
