

/* Main Container */
.signup-page {
 display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 1rem;
}


.logo-button {
  background-color: #fbc531;
  color: #333;
  font-size: 2rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 3px;
  width: 190px;
  height: 45px;
  transition: background-color 0.3s;
}

/* Logo Container */
.logo-container {
  margin-bottom: -20px; /* Login container ile arasındaki boşluk */
}






/* Signup Container */
.signup-container {
  background-color: #FFF;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.4);
  text-align: center;
  color: #333;
  width: 100%;
  max-width: 400px;
}

/* Logo Box */
.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}



/* Title and Info */
h2.signup-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
}

.email-info {
  font-size: 0.8rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: left;
}

/* Form */
form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
/* Input Group */
.input-group {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the inputs horizontally */
  gap: 1rem; /* Add spacing between inputs */
  width: 100%;
}

.input-group input {
  width: 80%; /* Adjust input width to ensure it's centered */
  max-width: 400px; /* Optional: Limit maximum width for larger screens */
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s;
}

.input-group input:focus {
  border-color: #00adb5;
  box-shadow: 0 0 6px rgba(0, 173, 181, 0.5);
}




/* Submit Button */
.submit-button {
  
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #00adb5;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the inputs horizontally */
  gap: 1rem; /* Add spacing between inputs */
  width: 100%;
  
  
  
  
}

.submit-button:hover {
  background-color: #007c82;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Already Account Section */
p.already-account {
  font-size: 0.9rem;
  margin-top: 1.5rem;
  color: #333;
  
}

.signup-footer-text {
  font-size: 0.8rem;
  color: #333;
  margin-top: 1.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .signup-container {
    padding: 1.5rem;
    width: 90%;
  }

  .signup-title {
    font-size: 1.5rem;
  }

  .email-info {
    font-size: 0.9rem;
  }

  .checkbox-group {
    font-size: 0.8rem;
  }

  .submit-button {
    padding: 0.6rem;
    font-size: 0.9rem;
  }
}


.info-text {
  font-size: 0.8rem;
  color: #666; /* Hafif gri ton */
  margin-top: 0.5rem; /* Üst boşluk */
  text-align: left;
  line-height: 1.5; /* Satır yüksekliği */
}

.info-text a {
  color: #007c82; /* Link rengi */
  text-decoration: none; /* Alt çizgi yok */
  font-weight: bold;
}

.info-text a:hover {
  text-decoration: underline; /* Üzerine gelince alt çizgi */
}
