.ad-container {
  width: 100%;
  max-width: 1000px;
  height: 200px;
  margin: 20px auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f3f4f6;
  border-radius: 8px;
  overflow: hidden;
  
  transition: transform 0.3s ease;
}

.ad-container:hover {
  transform: scale(1.02);
}

.ad-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.ad-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  color: #666;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border: 2px dashed #ccc;
}


/* 🔥 Reklam kapatma butonu */
.close-ad-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 3px;
}

.close-ad-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}