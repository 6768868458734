/* Genel Kart Stili */
.teacher-card {
  width: 340px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  transition: transform 0.3s;
  cursor: pointer;
}

.teacher-card:hover {
  transform: translateY(-5px);
}

/* Fiverr Stili Üst Kısım */
.teacher-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;
}

/* Profil Resmi */
.profile-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #007bff;
  object-fit: cover;
}

/* Kullanıcı Bilgileri */
.user-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Ad Soyad */
.user-name {
  font-size: 1em;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  gap: 6px;
}

/* Ders Bilgisi */
.user-subject {
  font-size: 0.8em;
  color: black;
  font-style: italic;
}

/* Rozet ve Talep Sayısı */
.badge-container {
  display: flex;
  gap: 12px; /* Talep ve Görüntüleme arasındaki boşluk */
  margin-top: 4px;
}

.request-count, .click-count {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.request-count-icon, .click-icon {
  margin-right: 5px;
  font-size: 14px;
  color: #f39c12; /* Sarı renk */
}
/* Öğretmen Bilgileri */
.teacher-name {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 3px;
}

.teacher-subject {
  font-style: italic;
  color: #777;
  font-size: 0.9em;
  margin-bottom: 10px;
}

/* Bilgi Satırları */
.info-box {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}

.info-box .info-icon {
  font-size: 16px;
  color: #fff;
  padding: 6px;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

/* Başlık ve Değer */
.info-details {
  display: flex;
  flex-direction: column;
}

.info-title {
  font-weight: bold;
  color: #444;
  font-size: 0.9em;
}

.info-value {
  color: #000;
  font-size: 0.9em;
}

/* Çift Sütunlu Bilgiler */
.two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  width: 100%;
}

/* Renk Temaları */
.bg-blue { background-color: #007bff; }
.bg-green { background-color: #27ae60; }
.bg-red { background-color: #e74c3c; }
.bg-yellow { background-color: #f39c12; }
.bg-purple { background-color: #8e44ad; }
.bg-teal { background-color: #1abc9c; }

/* Butonlar - Daha Küçük ve Estetik */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.request-button, .apply-button{
  flex: 1; /* Eşit genişlik */
  padding: 8px 12px;
  font-size: 0.85em;
  color: white;
  border: none;
  border-radius: 8px; /* Daha yuvarlak görünüm */
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px; /* İkon ile metin arasına boşluk */
}





/* Talep Aldım Butonu */
.request-button {
  background-color: #007bff;
  margin-right: 5px;
}

.request-button:hover {
  background-color: #0056b3;
}

/* Ders Talep Et Butonu */
.apply-button {
  background-color: #27ae60;
}

.apply-button:hover {
  background-color: #1e8449;
}

/* İkon Stili */
.button-icon {
  font-size: 14px;
}

.badges-container {
  display: flex;
  gap: 8px; /* Rozetler arasındaki boşluk */
  margin-top: 4px; /* İsim ile rozetler arasında boşluk */
}

.founder-badge, .top10-badge {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 15px;
}

.founder-badge {
  background-color: #007bff;
  color: white;
}

.top10-badge {
  background-color: #FFD700; /* Altın rengi */
  color: black;
}


