.ad-management {
  width: 90%;
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #000;
}

/* ---- Akordiyon Butonu ---- */
.accordion-button {
  background: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
  display: block;
  width: 100%;
  text-align: center;
}

.accordion-button.open {
  background: #0056b3;
}

.accordion-content {
  display: none;
  padding: 15px;
  background: white;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.accordion-content.show {
  display: block;
}

/* ---- Form Alanları ---- */
.accordion-content form {
  display: flex;
  flex-direction: column;
}

.accordion-content label {
  font-weight: bold;
  color: black;
  font-size: 14px;
  margin-top: 10px;
}

.accordion-content input {
  padding: 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  font-size: 14px;
  background: #e0e0e0;
  color: black;
  margin-bottom: 10px;
}

/* ---- Kaydet Butonu ---- */
.submit-button {
  background: #28a745;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.submit-button:hover {
  background: #218838;
}

/* ---- Reklam Listesi Tablosu ---- */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: gray;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

thead {
  background: #007bff;
  color: white;
}

thead th {
  padding: 12px;
  text-align: left;

}

tbody tr {
  border-bottom: 1px solid #ddd;
}

tbody tr:nth-child(even) {
  background: #f9f9f9;
color: black;
}

tbody td {
  padding: 10px;
  text-align: left;
}

/* ---- Rapor İndirme Butonu ---- */
.download-button {
  background: #ffc107;
  color: black;
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.download-button:hover {
  background: #e0a800;
}
