/* src/pages/Home.css */

.home {
    padding: 20px;
}

/* Featured News Section */
.featured-news {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    align-items: center;
}

.featured-text {
    flex: 1;
}

.featured-text .category {
    font-size: 14px;
    font-weight: bold;
    color: #d32f2f;
    margin-bottom: 5px;
}

.featured-text h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin: 10px 0;
}

.featured-text p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
}

.featured-image {
    flex: 1;
    text-align: center;
}

.featured-image img {
    width: 100%;
    height: auto;
    max-width: 400px;
    border-radius: 8px;
}

/* News Detail Section */
.news-detail {
    margin-top: 20px;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
}

.news-detail img {
    width: 100%;
    height: auto;
    margin-top: 10px;
    border-radius: 8px;
}

.news-detail h2 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
}

.news-detail p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
}

/* News Cards Section */
.news-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 30px;
}

.news-card {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.news-card:hover {
    transform: translateY(-5px);
}

.news-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.news-card-content {
    padding: 15px;
}

.news-category {
    color: #d32f2f;
    font-weight: bold;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
}

.news-title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .featured-news {
        flex-direction: column;
        padding: 15px;
        gap: 10px;
    }

    .featured-text h2 {
        font-size: 20px;
    }

    .featured-text p {
        font-size: 14px;
    }

    .news-detail h2 {
        font-size: 20px;
    }

    .news-detail p {
        font-size: 14px;
    }

    .news-cards {
        grid-template-columns: 1fr;
        gap: 15px;
    }
}
