/* Genel kapsayıcı */
.content-management {
  margin: 20px;
  font-family: Arial, sans-serif;
}

/* Başlıklar */
.content-management h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 15px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
  
}

/* Liste stili */
.content-management ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px;
  color: #333;
}

.content-management li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.content-management li:hover {
  background-color: #f1f1f1;
}

/* Düğmeler */
.content-management button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 5px;
  transition: background-color 0.3s;
}

.content-management button:hover {
  background-color: #0056b3;
}

.content-management button.delete {
  background-color: #dc3545;
}

.content-management button.delete:hover {
  background-color: #a71d2a;
}

/* Düzenleme formu */
.content-management form {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content-management form h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #333;
}

.content-management form input,
.content-management form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.content-management form button {
  width: 100%;
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.content-management form button:hover {
  background-color: #218838;
}
