.reset-password-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.reset-password-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
  width: 100%;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #43a047;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: #388e3c;
}
