.terms-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.terms-header {
  text-align: center;
  margin-bottom: 20px;
}

.terms-header h1 {
  font-size: 24px;
  color: #2c3e50;
}

.terms-header p {
  font-size: 14px;
  color: #7f8c8d;
}

.terms-content h2 {
  font-size: 18px;
  color: #34495e;
  margin-top: 20px;
}

.terms-content p {
  margin-bottom: 15px;
}

.terms-footer {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #7f8c8d;
}

.terms-footer strong {
  color: #34495e;
}


