/* Card Container */
.detailed-cv-form-section {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  background-color: #2a2a3a;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

/* Form Title */
.detailed-cv-form-section h2 {
  font-size: 1.5em;
  color: #4d9fff;
  text-align: center;
  margin-bottom: 20px;
}

/* Labels */
.detailed-cv-form-section label {
  display: block;
  margin: 10px 0 5px;
  font-weight: bold;
  color: #ffffff;
}

/* Inputs and Textarea */
.detailed-cv-form-section input,
.detailed-cv-form-section textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
  background-color: #3a3a4a;
  color: #ffffff;
}

.detailed-cv-form-section input:focus,
.detailed-cv-form-section textarea:focus {
  outline: none;
  border: 1px solid #4d9fff;
  box-shadow: 0 0 5px rgba(77, 159, 255, 0.5);
}

/* Submit Button */
.detailed-cv-form-section button[type="submit"] {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #4d9fff;
  color: #ffffff;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.detailed-cv-form-section button[type="submit"]:hover {
  background-color: #3a7ecc;
}
