/* Genel kapsayıcı */
.content-container {
  margin: 20px 0;
}

/* Video kapsayıcı */
.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.video-container iframe {
  width: 100%;
  max-width: 560px;
  height: 315px;
  border: none;
  border-radius: 8px;
}

/* Video başlığı */
.video-title-container {
  margin-bottom: 10px;
  text-align: center;
}

.video-title-container h3 {
  font-size: 1.2em;
  color: #444;
}

/* Haber kapsayıcı */
.news-container {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.news-container h3 {
  font-size: 1.2em;
  color: #333;
}

.news-container p {
  font-size: 1em;
  color: #333 !important;
}

.news-container small {
  font-size: 0.9em;
  color: #666;
}




