/* src/pages/ProfilePage.css */

.profile-page {
  display: flex;
  background-color: #FFF;
  color: #fff;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 200px;
  background-color: #34445b;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.sidebar-item {
  padding: 4px;
  border-radius: 8px;
  color: #fff;
  text-align: left;
  cursor: pointer;
  transition: background 0.3s;
}

.sidebar-item.active,
.sidebar-item:hover {
  background-color: #3f8ccc;
}

.content {
  flex: 1;
  padding: 20px;
  background-color: #fff;
}

.tab-content {
  background-color: #FFF;
  padding: 20px;
  border-radius: 8px;
}


p.tab-content {
  background-color: #FFF;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
}



.tab-item {
  margin-bottom: 20px; /* Bileşenler arasında boşluk */
}

.tab-item:last-child {
  margin-bottom: 0; /* Son bileşen için alt boşluk bırakma */
}



.content h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.application-section,
.status-section {
  margin-bottom: 20px;
}

.profile-management {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #1c1e26;
  border-radius: 8px;
}

.application-section,
.status-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.apply-button {
  padding: 10px 20px;
  color: #fff;
  background-color: #3f8ccc;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background 0.3s;
}

.apply-button:hover {
  background-color: #357aa5;
}

.status-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.status {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  display: inline-block;
}

/* Status color variations */
.status.değerlendirmede, .status.pending {
  color: #ffc107; /* Yellow for "Değerlendirmede" */
}

.status.onaylandı, .status.approved {
  color: #28a745; /* Green for "Onaylandı" */
}

.status.reddedildi {
  color: #dc3545; /* Red for "Reddedildi" */
}


.package-card {
  background-color: #FFF;
  padding: 20px;
  border-radius: 12px;
  width: 240px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
}