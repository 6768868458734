.teacher-application-form {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.teacher-application-form h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.teacher-application-form label {
    display: block;
    margin: 15px 0 5px;
    font-weight: bold;
    color: #333;
}

/* Style for text, number, email, and file inputs, as well as select and textarea */
.teacher-application-form input[type="text"],
.teacher-application-form input[type="number"],
.teacher-application-form input[type="email"],
.teacher-application-form input[type="file"],
.teacher-application-form select,
.teacher-application-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
    box-sizing: border-box;
    background-color: #fff;
    color: #333;
}

/* Slightly smaller padding for file input */
.teacher-application-form input[type="file"] {
    padding: 5px;
}

/* Focus effect for inputs, select, and textarea */
.teacher-application-form input:focus,
.teacher-application-form select:focus,
.teacher-application-form textarea:focus {
    outline: none;
    border-color: #3F8CCC;
    box-shadow: 0 0 5px rgba(63, 140, 204, 0.5);
}

.teacher-application-form button {
    width: 100%;
    padding: 12px;
    background-color: #3F8CCC;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1.1em;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.teacher-application-form button:hover {
    background-color: #3575a3;
    box-shadow: 0 4px 8px rgba(53, 117, 163, 0.3);
}

/* Responsive Design */
@media (max-width: 600px) {
    .teacher-application-form {
        padding: 15px;
    }
}

.submission-message {
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    font-size: 1.1em;
    color: #333;
    margin-top: 20px;
}


.photo-tool-info {
  margin: 10px 0;
  font-size: 14px;
  color: #555;
}

.photo-tool-btn {
  display: inline-block;
  margin-left: 5px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.photo-tool-btn:hover {
  background-color: #0056b3;
}
