.photo-tool {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f3f3f3;
  min-height: 100vh;
}

.photo-tool h1 {
  color: #333;
}

.photo-tool p {
  color: #666;
  font-size: 14px;
}

input[type="file"] {
  margin: 20px 0;
}

.editor {
  margin: 20px auto;
  position: relative;
  width: fit-content;
  height: fit-content;
}

canvas {
  display: block;
  margin: auto;
}

button {
  margin: 10px 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.save-btn {
  background-color: #007bff;
  color: white;
}

.save-btn:hover {
  background-color: #0056b3;
}

.continue-btn {
  background-color: #28a745;
  color: white;
}

.continue-btn:hover {
  background-color: #218838;
}
