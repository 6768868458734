/* Main Container */
.admin-login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 1rem;
}

/* Logo and Header */
.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

h2.admin-login-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
  text-align: center;
}

/* Login Container */
.admin-login-container {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.4);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

/* Form */
form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.input-group input {
  width: 80%;
  max-width: 400px;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s;
}

.input-group input:focus {
  border-color: #007c82;
  box-shadow: 0 0 6px rgba(0, 124, 130, 0.5);
}

/* Submit Button */
.submit-button {
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #007c82;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 80%;
  max-width: 400px;
}

.submit-button:hover {
  background-color: #005960;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Error Message */
.error-message {
  font-size: 0.9rem;
  color: #ff4d4f;
  margin-top: 0.5rem;
}

/* Info Text */
.info-text {
  font-size: 0.9rem;
  color: #666;
  margin-top: 1.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-login-container {
    padding: 1.5rem;
    width: 90%;
  }

  h2.admin-login-title {
    font-size: 1.8rem;
  }

  .submit-button {
    padding: 0.6rem;
    font-size: 0.9rem;
  }
}
