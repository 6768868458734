/* src/pages/AdminDashboard.css */

.admin-dashboard {
  display: flex;
  background-color: #1c1e26;
  color: #fff;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 200px;
  background-color: #2d2f3b;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar-item {
  padding: 10px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;
}

.sidebar-item.active,
.sidebar-item:hover {
  background-color: #3f8ccc;
}

.content {
  flex: 1;
  padding: 20px;
}

.content h1 {
  font-size: 24px;
  color: #f0f0f0;
  margin-bottom: 10px;
}

.content h2 {
  font-size: 18px;
  color: #f0f0f0;
  margin-bottom: 20px;
}

.metrics, .city-metrics {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to fit multiple rows */
  gap: 20px;
  margin-bottom: 20px;
}

.metric-card {
  background-color: #2d2f3b;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: calc(25% - 20px); /* Adjusted width to fit 4 per row with gap */
  min-width: 150px; /* Ensures a minimum size for responsive design */
}

.metric-card span {
  display: block;
  font-size: 0.9em;
  color: #ddd;
}

.metric-card strong {
  display: block;
  font-size: 1.5em;
  color: #fff;
  margin-top: 5px;
}

.chart-container {
  background-color: #2d2f3b;
  padding: 20px;
  border-radius: 8px;
}


.applications {
  margin-top: 20px;
  padding: 20px;
  background-color: #2b2f3a; /* Darker background */
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.applications h2 {
  color: #f4f4f9;
  font-size: 24px;
  margin-bottom: 20px;
}

.applications-table {
  width: 100%;
  border-collapse: collapse;
}

.applications-table th, .applications-table td {
  padding: 12px 16px;
  text-align: left;
  color: #f4f4f9;
}

.applications-table th {
  background-color: #3b3f51;
  color: #a3a9c7;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}

.applications-table tr {
  background-color: #3b3f51;
}

.applications-table tr:nth-child(even) {
  background-color: #333744;
}

.applications-table tr:hover {
  background-color: #454b5f;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.view-btn, .approve-btn, .reject-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-btn {
  background-color: #4a90e2;
  color: #fff;
}

.view-btn:hover {
  background-color: #357ABD;
}

.approve-btn {
  background-color: #5cb85c;
  color: #fff;
}

.approve-btn:hover {
  background-color: #4e9d4e;
}

.reject-btn {
  background-color: #d9534f;
  color: #fff;
}

.reject-btn:hover {
  background-color: #c9302c;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}


