.paketler-page {
  text-align: center;
  color: #fff;
  font-family: Arial, sans-serif;
  background-color: #fff;
  padding: 20px;
}

h1.title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #000; /* Siyah renk */
}

.packages {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.package-card {
  background-color: #2a2a3a;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.popular {
  border: 2px solid #ff8abf;
}

.popular-label {
  position: absolute;
  top: -25px;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust to center */
  background-color: #F06060;
  color: #fff;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
}


.package-card h2 {
  font-size: 1.5em;
  margin: 0;
  color:#333;
}

.price {
  font-size: 2em;
  color: #F06060;
  margin: 10px 0;
}

.description {
  font-size: 0.9em;
  color: #333;
  margin: 5px 0;
}

.package-button {
  margin-top: auto;
  padding: 10px;
  background-color: #F06060;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}


.purchase-button {
  margin-top: auto;
  padding: 10px;
  background-color: #F06060;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.package-button:hover {
  background-color: #e07cad;
}


.purchase-button:hover {
  background-color: #e07cad;
}