/* Main Container */
.cv-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

/* Header Section */
.cv-header {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.cv-header h2 {
  font-size: 1.8em;
  color: #4d9fff;
}

.cv-header p {
  font-size: 1em;
  color: #555;
  margin-top: 10px;
}


/* Form Section */
.cv-form-section {
 width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}



/* Submit Button */
.submit-button {
  background-color: green;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1;
  margin-right: 10px;
}

.submit-button:hover {
  background-color: blue;
}


