/* General Header Styling */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #34445b; /* Dark blue */
  padding: 10px 20px;
  font-family: Arial, sans-serif;
  color: #fff;
  position: relative; /* For dropdown positioning on mobile */
  z-index: 1000;
}

.search-button {
  all: unset; /* Varsayılan tarayıcı stilini sıfırlar */
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

/* Logo Container */
.logo-container {
  margin-top: 10px;
  display: flex;
  align-items: center; /* ✅ Dikeyde ortalama */
  height: 100%;
}

/* Search Section */
.search-container {
  
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  justify-content: center;
 height: 45px; /* ✅ Yüksekliği diğer elemanlarla aynı yap */

}

.search-input {
  padding: 10px;
  width: 250px;
  border: 1px solid #dcdde1;
  border-radius: 5px;
  font-size: 14px;
  color: black;
}

.search-text {
  color: black;
  font-size: 14px;
}

/* Auth Links */
.auth-container {
  display: flex;
  align-items: center;
  gap: 15px;
  transition: max-height 0.3s ease-in-out;
}

.auth-link,
.username {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.auth-link:hover,
.username:hover {
  color: #fbc531;
}

.divider {
  color: #fff;
}


.logo-button {
  background-color: #fbc531;
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
  border: none;
 height: 45px; /* ✅ Yüksekliği diğer elemanlarla aynı yap */
  border-radius: 5px;
  height: 45px;
  padding: 5px 8px ;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.3s;
  position: relative;
  align-items: center;
}

.logo-button:hover {
  background-color: #e1a825;
}

/* Masaüstü için tam logo görünsün, mobil logo gizlensin */
.desktop-logo-text {
  display: inline;
}

.mobile-logo-text {
  display: none;
}




/* Profile Button */
.profile-button {
  background-color: #fbc531;
  color: #222831;
  font-size: 0.85rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 5px;
  width: 80px;
  height: 30px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profile-button:hover {
  background-color: #f1a902;
}

/* User Section Styling */
.user-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.welcome-message {
  font-size: 0.9rem;
  color: #fff;
}

/* Logout Button */
.logout {
  color: #fbc531;
  cursor: pointer;
  font-weight: bold;
}

.logout:hover {
  text-decoration: underline;
  color: #fff;
}

/* Hamburger Menu for Mobile */
.hamburger {
  display: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  z-index: 1010;
}

/* Responsive Design */
@media (max-width: 768px) {
 

  .hamburger {
    display: block; /* Display hamburger icon on mobile */
  }

  .auth-container {
    flex-direction: column;
    align-items: flex-start;
    background-color: #34445b;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    z-index: 1000;
  }

  .auth-container.open {
    max-height: 300px; /* Show dropdown when open */
  }

  .user-section {
    flex-direction: column; /* Stack items vertically on mobile */
    gap: 5px;
    width: 100%;
    align-items: flex-start;
    padding: 10px;
  }

  .profile-button {
    width: 90%; /* Full width button for mobile */
    height: auto;
    margin: 10px 0;
  }

.desktop-logo-text {
    display: none;
  }
  .mobile-logo-text {
    display: inline;
  }
}

@media (max-width: 768px) {
  .divider {
    display: none; /* Mobil görünümde gizle */
  }
}

@media (max-width: 768px) {
  .search-button {
    display: none;
  }
}
@media (max-width: 768px) {
  .auth-container {
    gap: 10px; 
    padding-top: 10px; 
  }
}

/* General Header Styling */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #34445b; /* Dark blue */
  padding: 10px 20px;
  font-family: Arial, sans-serif;
  color: #fff;
  position: relative; /* For dropdown positioning on mobile */
  z-index: 1000;
}

/* Logo Container */
.logo-container {
  margin-top: 10px;
  display: flex;
  align-items: center; /* ✅ Dikeyde ortalama */
  height: 100%;
}

/* Search Section */
.search-container {
  
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  justify-content: center;
 height: 45px; /* ✅ Yüksekliği diğer elemanlarla aynı yap */

}

.search-input {
  padding: 10px;
  width: 250px;
  border: 1px solid #dcdde1;
  border-radius: 5px;
  font-size: 14px;
  color: black;
}

.search-text {
  color: black;
  font-size: 14px;
}

/* Auth Links */
.auth-container {
  display: flex;
  align-items: center;
  gap: 15px;
  transition: max-height 0.3s ease-in-out;
}

.auth-link,
.username {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.auth-link:hover,
.username:hover {
  color: #fbc531;
}

.divider {
  color: #fff;
}


.logo-button {
  background-color: #fbc531;
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
  border: none;
 height: 45px; /* ✅ Yüksekliği diğer elemanlarla aynı yap */
  border-radius: 5px;
  height: 45px;
  padding: 5px 8px ;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.3s;
  position: relative;
  align-items: center;
}

.logo-button:hover {
  background-color: #e1a825;
}

/* Masaüstü için tam logo görünsün, mobil logo gizlensin */
.desktop-logo-text {
  display: inline;
}

.mobile-logo-text {
  display: none;
}




/* Profile Button */
.profile-button {
  background-color: #fbc531;
  color: #222831;
  font-size: 0.85rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 5px;
  width: 80px;
  height: 30px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profile-button:hover {
  background-color: #f1a902;
}

/* User Section Styling */
.user-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.welcome-message {
  font-size: 0.9rem;
  color: #fff;
}

/* Logout Button */
.logout {
  color: #fbc531;
  cursor: pointer;
  font-weight: bold;
}

.logout:hover {
  text-decoration: underline;
  color: #fff;
}

/* Hamburger Menu for Mobile */
.hamburger {
  display: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  z-index: 1010;
}

/* Responsive Design */
@media (max-width: 768px) {
 

  .hamburger {
    display: block; /* Display hamburger icon on mobile */
  }

  .auth-container {
    flex-direction: column;
    align-items: flex-start;
    background-color: #34445b;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    z-index: 1000;
  }

  .auth-container.open {
    max-height: 300px; /* Show dropdown when open */
  }

  .user-section {
    flex-direction: column; /* Stack items vertically on mobile */
    gap: 5px;
    width: 100%;
    align-items: flex-start;
    padding: 10px;
  }

  .profile-button {
    width: 90%; /* Full width button for mobile */
    height: auto;
    margin: 10px 0;
  }

.desktop-logo-text {
    display: none;
  }
  .mobile-logo-text {
    display: inline;
  }
}

@media (max-width: 768px) {
  .divider {
    display: none; /* Mobil görünümde gizle */
  }
}

@media (max-width: 768px) {
  .search-button {
    display: none;
  }
}
@media (max-width: 768px) {
  .auth-container {
    gap: 10px; 
    padding-top: 10px; 
  }
}



@media (max-width: 768px) {
  .logo-container {
    width: auto;  /* İçeriğe göre genişliği belirle */
    max-width: 80px; /* Maksimum genişliği sınırla */
  }

  .logo-button {
    font-size: 1.2rem; /* Yazı boyutunu küçült */
    padding: 5px 10px; /* Buton iç boşluklarını azalt */
    max-width: 80px; /* Butonun fazla genişlemesini engelle */
  }
}
@media (max-width: 768px) {
  .search-input {
    width: 150px !important; /* Genişliği 150px olarak sabitle */
    max-width: 150px;
  }
}
