/* Sayfanın Genel Stili */
.team-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f3f4f6;
  
}

/* Filtre Bölgesi */
.filters-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  gap: 12px;
  width: 100%;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
}

/* Kategori Grid (6x2 Düzeni) */
.categories-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  width: 100%;
}

/* Kategori Butonları */
.category-button {
  padding: 10px 14px;
  border-radius: 6px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease-in-out;
  border: none;
  font-size: 0.8em;
}

/* Seçili Buton */
.category-button.selected {
  background-color: #2c3e50;
  color: white;
}

/* Varsayılan Buton */
.category-button.default {
  background-color: #facc15;
  color: #374151;
}

.category-button.default:hover {
  background-color: #fbbf24;
}

/* Hafif Yükselme Efekti */
.category-button:hover {
  transform: translateY(-2px);
}

/* Filtreleri Sıfırla Butonu */
.reset-button {
  padding: 8px 12px; /* Butonun iç boşluğunu biraz küçült */
  background-color: #2c3e50;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8em; /* Yazı boyutunu küçült */
  transition: background-color 0.3s, transform 0.2s ease-in-out;
}

.reset-button:hover {
  background-color: #1b2838;
  transform: translateY(-2px);
}


/* Filtreleme Alanı */
.filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  width: 100%;
}

/* Filtre Seçenekleri */
/* Filtre Seçenekleri */
.filter-select {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 0.8em;
  width: 100%;
  min-width: 100px; /* Optional: Add if you want a minimum width */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}




/* Filtre Sıfırlama Butonunu En Alta Al */
.filter-actions {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

/* Öğretmen Kartları */
.teachers-container {
  padding: 16px;
}

/* Başlık */
.teachers-title {
  font-size: 1.5em;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 16px;
  text-align: center;
}

/* Öğretmen Kartları için Grid Yapısı */
.teachers-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}

/* Responsive: Tablet */
@media (min-width: 640px) {
  .teachers-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Responsive: Desktop */
@media (min-width: 1024px) {
  .teachers-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Hiç Öğretmen Bulunamadı Mesajı */
.no-teachers {
  text-align: center;
  color: #6b7280;
  grid-column: span 3;
}

/* 📌 Mobil Uyumluluk - Kategoriler Kaydırılabilir */
@media (max-width: 768px) {
  .categories-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    justify-content: center;
  }

.teacher-card {
    margin-left: -29px; /* Kartı sola kaydır */
    width: calc(100% - 18px); /* Ekran genişliğine daha iyi uyum sağlasın */
  }
  
  .category-button {
    min-width: 75px;
	min-height: 75px;
    flex: 0 0 auto;
  }

  .filters {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .filter-select,
  .filter-input {
    width: 100%;
  }

  .filter-actions {
    flex-direction: column;
	
    align-items: center;
  }
}
